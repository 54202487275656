import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ["channel", "program"]

  connect () {
    this.toggleActiveClass(this.channelTarget)
    if (this.channelTarget.dataset.channel) {
      this.fetchProgram(this.channelTarget.dataset.channel)
    } else {
      this.displayLink(this.channelTarget)
    }
  }
  toggleActiveClass (channelContainer) {
    this.channelTargets.forEach((channel) => {
      channel.classList.remove("active")
    })
    channelContainer.classList.add("active")
  }

  formatDate (str) {
    const date = new Date(str)
    const day = date.getDate()
    const month = date.getMonth() + 1
    const hours = date.getHours().toString()
    const minutes = date.getMinutes().toString()
    return `${day}/${month}, ${hours.padStart(2, '0')}:${minutes.padStart(2, '0')}`
  }

  addProgram (program) {
    this.programTarget.insertAdjacentHTML('beforeend', `
      <div class="rit-card ml-1">
        <h5>${program.attributes.title}</h5>
        ${program.attributes.sub_title ? '<h6><em>' + program.attributes.sub_title + '</em></h6>' : ''}
        ${program.attributes.category ? '<span class="text-muted small">' + program.attributes.category + '</span>' : ''}
        <p>${this.formatDate(program.attributes.start_time)} <i class="fas fa-arrow-right"></i> ${this.formatDate(program.attributes.stop_time)}</p>
        ${program.attributes.description ? '<p>' + program.attributes.description + '</p>' : ''}
      </div>
      `)
  }

  extraChannelTranslation(locale) {
    switch (locale) {
      case "fr":
        return "Consulter les programmes de cette chaîne directement depuis leur site"
      case "es":
        return "Consulte los programas de este canal directamente desde su página web"
      default:
       return "Consult the programmes of this channel directly from their website"
    }
  }

  displayLink(wrapper) {
    const link = wrapper.dataset.link
    const locale = wrapper.dataset.locale
    this.programTarget.innerHTML = ""
    this.programTarget.insertAdjacentHTML('beforeend', `
      <div class="rit-card my-3 ml-1 p-2 text-center">
        <p>
          ${this.extraChannelTranslation(locale)}
        </p>
        <p>
          <a  href="${link}" target="_blank"%>Veuillez suivre ce lien</a>
        </p>
      </div>
      `)
  }

  fetchProgram(channel) {
    if (!channel) {
      console.log("test");
    } else {
      const url = `https://rit-tv-guide-api.herokuapp.com/api/v1/programs?daily=true&channel=${channel}`
      fetch(url).then(response =>  response.json() )
                .then((data) => {
                  this.programTarget.innerHTML = ""
                  data['data'].forEach((program) => {
                    this.addProgram(program)
                  })
                })
    }
  }

  switchChannel (e) {
    const channelTeleramaId = e.currentTarget.dataset.channel
    const channelWrapper = e.currentTarget
    this.toggleActiveClass(e.currentTarget)
    if (channelTeleramaId) {
      this.fetchProgram(channelTeleramaId)
    }  else {
      this.displayLink(channelWrapper)
    }
  }
}


