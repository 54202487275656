import { Controller } from "stimulus"

export default class extends Controller {

  static targets = [
                    "select",
                    "optionsForm",
                    "template",
                    "target",
                    "formWrapper",
                    "questionWrapper",
                    "alertCheckBox",
                    "minRatingWrapper"
                    ]

  connect() {
   this.selectTargets.forEach(select => this.toggleOptionForm(select))
   this.setQuestionsChildIndex()
   this.setMinRatingVisibility()
  }

  setMinRatingVisibility () {
    if (!this.hasAlertCheckBoxTarget) { return }
   this.alertCheckBoxTarget.checked ? this.minRatingWrapperTarget.classList.remove("invisible") : this.minRatingWrapperTarget.classList.add("invisible")
  }

  setQuestionsChildIndex() {
   this.questionWrapperTargets.forEach((wrapper) => {
     this.setChildIndex(wrapper)
   })
  }

  setChildIndex(questionWrapper) {
    const questionPosition = questionWrapper.querySelector(".question_position").value
    const inputList = questionWrapper.querySelectorAll("[name*='survey[questions_attributes]']")
    const index = questionPosition ? questionPosition : 1
    inputList.forEach((input) => {
      this.replaceInputNameAndId(input, index)
    })
    if (this.isHiddenInput(questionWrapper.nextElementSibling)) {
      this.replaceInputNameAndId(questionWrapper.nextElementSibling, index)
    }
  }

  replaceInputNameAndId(input, index) {
    input.name = input.name.replace(/INDEX_TO_CHANGE/g, index)
    input.id = input.id.replace(/INDEX_TO_CHANGE/g, index)
  }

  isHiddenInput(el) {
    return el.tagName === "INPUT" && el.type === "hidden"
  }

  toggleOptionForm(select) {
    const wrapper = select.closest(".nested-form-wrapper")
    const optionsForm = wrapper.querySelector(".question-options")
    if (select.value === "string") {
      optionsForm.classList.remove("d-none")
    } else {
      optionsForm.classList.add("d-none")
    }
  }

  addOptionInput(e) {
    const optionInputId = new Date().getTime().toString()
    const questionTranslationList = this.getAllQuestionTranslationWrappers(e.currentTarget)
    questionTranslationList.forEach((questionFormWrapper) => {
      const target = questionFormWrapper.querySelector("[data-survey-form-target='target']")
      const content = questionFormWrapper.querySelector('template').innerHTML.replace(/NEW_OPTION_RECORD/g, optionInputId)
      target.insertAdjacentHTML('beforebegin', content)
    })
    this.setQuestionsChildIndex()
  }

  getAllQuestionTranslationWrappers(element) {
    const formWrapper = element.closest(".nested-form-wrapper")
    const hiddenInput = formWrapper.querySelector("input[type='hidden']")
    const questionPosition = this.getQuestionIndex(hiddenInput)
    return this.formWrapperTargets.map((formWrapper) => {
      const input = formWrapper.querySelector(`input[name*='survey[questions_attributes][${questionPosition}]']`)
      return input.closest(".nested-form-wrapper")
    })
  }

  changeValue(e) {
    const selectValue = e.currentTarget.value
    const questionTranslationList = this.getAllQuestionTranslationWrappers(e.currentTarget)
    questionTranslationList.forEach((translation) => {
      translation.querySelector("select").value = selectValue
      this.toggleOptionForm(translation.querySelector("select"))
    })
  }

  getCurrentFormWrapper(element) {
    return element.closest("[data-survey-form-target='formWrapper']")
  }

  getTranslationFormWrapperList(currentFormWrapper) {
    return this.formWrapperTargets.filter(formWrapper => formWrapper !== currentFormWrapper)
  }

  getQuestionIndex(input) {
    const re = /\w*\[\w*\]\[(\d*)]/
    return input.name.match(re)[1]
  }

  getOptionIndex(input) {
    const re = /\w*\[\w*\]\[\d*]\[\w*\]\[(\d*)]/
    return input.name.match(re)[1]
  }

  addQuestionTranslationInput(e) {
    const currentFormWrapper = this.getCurrentFormWrapper(e.currentTarget)
    const questionList = currentFormWrapper.querySelectorAll(".nested-form-wrapper")
    const lastQuestion = questionList[questionList.length - 1]
    const lastQuestionInput = lastQuestion.querySelector("input")
    const lastQuestionId = this.getQuestionIndex(lastQuestionInput)

    const otherFormWrapperList = this.getTranslationFormWrapperList(currentFormWrapper)
    otherFormWrapperList.forEach((formWrapper) => {
      const content = formWrapper.querySelector('template').innerHTML.replace(/NEW_RECORD/g, lastQuestionId)
      formWrapper.querySelector('[data-nested-form-target="target"]').insertAdjacentHTML('beforebegin', content)
    })
  }

  removeQuestion(e) {
    const questionTranslationList = this.getAllQuestionTranslationWrappers(e.target)
    questionTranslationList.forEach((wrapper) => {
      if (wrapper.dataset.newRecord === 'true') {
        wrapper.remove()
      } else {
        wrapper.style.display = 'none'
        const input = wrapper.querySelector("input[name*='_destroy]")
        input.value = '1'
      }
    })
  }

  removeOption(e) {
    const questionTranslationList = this.getAllQuestionTranslationWrappers(e.target)
    const questionInput = e.currentTarget.closest(".nested-form-wrapper").querySelector("input")
    const questionId = this.getQuestionIndex(questionInput)
    const optionInput = e.currentTarget.closest(".nested-option-form-wrapper").querySelector("input")
    const optionId = this.getOptionIndex(optionInput)
    const optionList = document.querySelectorAll(`[name^='survey[questions_attributes][${questionId}][question_options_attributes][${optionId}][content_']`)

    optionList.forEach((option) => {
      const wrapper = option.closest(".nested-option-form-wrapper")
      if (wrapper.dataset.newRecord === 'true') {
        wrapper.remove()
      } else {
        wrapper.style.display = 'none'
        const input = wrapper.querySelector("input[name*='_destroy]")
        input.value = '1'
      }
    })

  }
}
