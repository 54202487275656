import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["quantity", "form", "iconMinus", "iconPlus", "deliveryTime", "deliveryTimeForm"]

  connect() {
    if (this.hasFormTarget) {
      this.setOpacity()
    }
  }

  updateQuantity (event) {
    this.update(this.quantityTarget.value);
    this.setOpacity()
  }

  setOpacity() {
    if (this.quantityTarget.min == this.quantityTarget.value) {
      this.iconMinusTarget.classList.add("opacity-50")
    } else {
      this.iconMinusTarget.classList.remove("opacity-50")
    }
    if (this.quantityTarget.max == this.quantityTarget.value) {
      this.iconPlusTarget.classList.add("opacity-50")
    } else {
      this.iconPlusTarget.classList.remove("opacity-50")
    }
  }

  increment() {
    let quantity = parseInt(this.quantityTarget.value, 10)
    let maxQuantity = parseInt(this.quantityTarget.max, 10)

   if (quantity >= maxQuantity) return

    quantity += 1
    this.quantityTarget.value = quantity

    this.update(quantity)
    this.setOpacity()
  }

  decrement() {
    let quantity = parseInt(this.quantityTarget.value, 10)
    let maxQuantity = parseInt(this.quantityTarget.max, 10)

   if (quantity < 1) return

    quantity -= 1
    this.quantityTarget.value = quantity

    this.update(quantity)
    this.setOpacity()
  }

  update(quantity) {
    const url = this.formTarget.action
    const body = new FormData(this.formTarget)
    fetch(url, {
      method: 'POST',
      header: {'Accept': 'text/html'},
      body: body
    }).then(response => response.text())
    .then((data) => {
      const itemsCount = document.querySelector('.room-cart__items-count')
      itemsCount.innerText = data === "0" ? "" : data
    })
  }

  toggleForm() {
    this.deliveryTimeTarget.classList.toggle('d-none')
    this.deliveryTimeFormTarget.classList.toggle('d-none')
  }

}
