import { Controller } from "stimulus"
import consumer from "../channels/consumer"

export default class extends Controller {
  static values = { chatroomId: Number }
  static targets = ["messages", "form"]

  connect() {
    this.channel = consumer.subscriptions.create(
      { channel: "ChatroomChannel", id: this.chatroomIdValue },
      { received: data => this.#insertMessageScrollDownAndResetForm(data) }
    )
  }
  
  #insertMessageScrollDownAndResetForm(data) {
    const hotelMessagesContainer = document.getElementById("hotel-messages-container")
    const roomMessagesContainer = document.getElementById("room-messages-container")
    
    const message = hotelMessagesContainer ? data.message_hotel : data.message_room
    this.messagesTarget.insertAdjacentHTML("beforeend", message)
    if (hotelMessagesContainer) {
      this.formTarget.scrollIntoView({block: "end", behavior: "smooth"})
    } else {
      this.messagesTarget.scrollTo({top: this.messagesTarget.scrollHeight, behavior: "smooth"})
    }

    this.formTarget.reset()
  }

  disconnect() {
    this.channel.unsubscribe()
  }
}