import { Controller } from 'stimulus';
import "jquery-bar-rating";

export default class extends Controller {
  static targets = ["rating"]

  connect() {
    $(this.ratingTarget).barrating({
      theme: 'css-stars'
    });
  }
}
