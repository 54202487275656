import NestedForm from "stimulus-rails-nested-form"

export default class extends NestedForm {
  // connect() {
  //   super.connect()
  // }

  archive(e) {
    const wrapper = e.target.closest(".nested-form-wrapper")
    if (wrapper.dataset.newRecord === 'true') {
      wrapper.remove()
    } else {
      wrapper.style.display = 'none'
      const input = wrapper.querySelector("input[name*='archived']")
      input.value = 'true'
    }
  }
}
