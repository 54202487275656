import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["formWrapper"]

  connect() {
    this.formWrapperTargets.forEach((form) => {
      this.setFontStyle(form)

    })
  }

  setFontStyle(form) {
    const labels = form.querySelectorAll(".form-check-label")
    labels.forEach((label) => {
      label.style.fontFamily = label.innerText
    })

  }
}
