import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ["input", "form"]

  toogleCheck(){
    this.inputTargets.forEach((input) => {
      if (input.checked) {
        input.classList.add('active')
      } else {
        input.classList.remove('active')
        input.removeAttribute('checked')
      }
    })
  }

  submitForm(e) {
    const form = this.hasFormTarget ? this.formTarget : e.target.closest('form')
    form.requestSubmit()
  }

}
