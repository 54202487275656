import { Controller } from "stimulus"
import flatpickr from "flatpickr";

export default class extends Controller {
  static values = {
    bookingId: Number
  }
  static targets = ["room", "dates"]

  connect() {
    if (this.bookingIdValue || this.roomTarget.value ) {
      this.roomId = this.roomTarget.value
      this.#setAnddisplayDatepickers()
    }
    $(this.roomTarget).on("select2:select", (e) => {
      this.roomId = e.params.data.id
      if (this.roomId) {
        this.#setAnddisplayDatepickers()
      } else {
        this.#resetAndHideDatepickers()
      }
    })
  }

  #setAnddisplayDatepickers() {
    this.#getUnavailableDates()
        .then(unavailableDates => {
          this.#updateDatepickersAvailabilities(unavailableDates)
          this.#showDates()
        })
  }

  #updateDatepickersAvailabilities(unavailableDates) {
    this.datesTarget.querySelectorAll("input.flatpickr-input").forEach(input => {
      const fp = input._flatpickr
      fp.config.disable = unavailableDates
      fp.redraw()
    });
  }

  #getUnavailableDates () {
    let url = `/api/v1/rooms/${this.roomId}/unavailable_dates`
    if (this.bookingIdValue) {
      url += `?booking_id=${this.bookingIdValue}`
    }
    return fetch(url, {}).then(response => response.json())
      .then((data) => data)
  }
  
  #resetAndHideDatepickers() {
    this.datesTarget.querySelectorAll("input.flatpickr-input").forEach(input => {
      input.value = null
    });
    this.#hideDates()
  }

  #showDates() {
    this.datesTarget.classList.remove('d-none')
  }

  #hideDates() {
    this.datesTarget.classList.add('d-none')
  }
}



