import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ["timeZone", "input"]

  connect() {
    this.timeZoneTarget.value = this.getClientTimeZone()
    this.inputTargets.forEach((input) => {
      input.value = input.type === 'datetime' ? this.formattedDate(input) : this.formattedTime(input)
    })
  }

  getClientTimeZone() {
    return Intl.DateTimeFormat().resolvedOptions().timeZone
  }

  formattedDate(input) {
    let zonedDate
    if (input.value === "") {
      zonedDate = new Date(Date.now())
    } else {
      zonedDate =  new Date(`${input.value}Z`)
    }
    const year = zonedDate.getFullYear()
    const month = (zonedDate.getMonth() + 1).toString().padStart(2, "0")
    const day = zonedDate.getDate().toString().padStart(2, "0")
    const time = zonedDate.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})
    return `${year}-${month}-${day}T${time}`
  }

  formattedTime(input) {
    let localTime
    if (input.value === "") {
      localTime = null 
    } else {
      localTime
      const date = new Date()
      console.log(date);
      const [hour, min, sec] = input.value.split(':')
      date.setUTCHours(hour)
      date.setUTCMinutes(min)
      localTime = date.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})
    }

    return localTime 
  }
}
