const localTimeFr = () => {
  return {
    date: {
      dayNames: ["Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"],
      abbrDayNames: ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"],
      monthNames: ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Decembre"],
      abbrMonthNames: ["Jan", "Fév", "Mar", "Avr", "Mai", "Juin", "Juil", "Août", "Sept", "Oct", "Nov", "Déc"],
      yesterday: "hier",
      today: "aujourd'hui",
      tomorrow: "demain",
      on: "Le {date}",
      formats: {
        "default": "%e %b, %Y",
        thisYear: "%e %b"
      }
    },
      time: {
        am: "am",
        pm: "pm",
        singular: "une {time}",
        singularAn: "une {time}",
        elapsed: "il y a {time}",
        second: "seconde",
        seconds: "secondes",
        minute: "minute",
        minutes: "minutes",
        hour: "heure",
        hours: "heures",
        formats: {
          "default": "%H:%M"
        }
      },
      datetime: {
        at: "{date} à {time}",
        formats: {
          "default": "%B %e, %Y à %H:%M %Z"
        }
      }
  }
}

const localTimeEs = () => {
  return {
    date: {
      dayNames: ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"],
      abbrDayNames: ["Dom", "Lun", "Mar", "Mié", "Jue", "Vie", "Sáb"],
      monthNames: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"],
      abbrMonthNames: ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"],
      yesterday: "ayer",
      today: "hoy",
      tomorrow: "mañana",
      on: "El {date}",
      formats: {
        "default": "%e %b, %Y",
        thisYear: "%e %b"
      }
    },
      time: {
        am: "am",
        pm: "pm",
        singular: "1 {time}",
        singularAn: "1 {time}",
        elapsed: "hace {time}",
        second: "segundo",
        seconds: "segundos",
        minute: "minuto",
        minutes: "minutos",
        hour: "hora",
        hours: "horas",
        formats: {
          "default": "%H:%M"
        }
      },
      datetime: {
        at: "{date} a las {time}",
        formats: {
          "default": "%B %e, %Y à %H:%M %Z"
        }
      }
  }
}

export { localTimeFr, localTimeEs }
