const initTooltip = () => {
  $(function () {
    $('[data-toggle="tooltip"]').tooltip({
      trigger : 'hover',
      container: 'body',
      html: true
    })
  })
  $('a').click(function(){
    $('[data-toggle="tooltip"]').tooltip('hide');
  });
};

export { initTooltip };
