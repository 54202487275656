import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["roomName", "form", "input", "card"]

  displayForm() {
    this.formTarget.classList.remove('d-none')
    this.roomNameTarget.classList.add('d-none')
  }

  update() {
    const newName = this.inputTarget.value
    const url = this.formTarget.action
    const body = new FormData(this.formTarget)
    body.append('name', newName)
    fetch(url, {
      method: 'PATCH',
      header: {'Accept': 'text/html'},
      body: body
    }).then(response => response.text())
      .then((data) => {
        this.cardTarget.innerHTML = data
      })
  }

  keydown(event) {
    if (event.key === 'Enter') {
      event.preventDefault()
      this.update()
    }
  }
}
