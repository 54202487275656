import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["tab", "section"]
  connect() {
    this.initClass()
  }
  initClass() {
    this.tabTarget.classList.add("active")
    this.sectionTarget.classList.remove("d-none")
  }
  
  toggleSection(e) {
    const tab = e.currentTarget
    const target = tab.dataset.target
    const section = this.sectionTargets.find(section => section.dataset.target === target)

    this.tabTargets.forEach(tab => tab.classList.remove("active"))
    tab.classList.add("active")
    this.sectionTargets.forEach(section => section.classList.add("d-none"))
    section.classList.remove("d-none")
  }
}
