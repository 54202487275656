import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    "checkboxExtra", 
    "inputExtraWrapper", 
    "inputExtra",
    "categorySelect",
    "productSizesWrapper",
    "productSizesCheckbox"
  ]

  connect() {
    if (this.inputExtraTarget.value) {
      this.inputExtraWrapperTarget.classList.remove("invisible")
      this.checkboxExtraTarget.checked = true
    }
  }

  toggleInputExtra(e) {
    this.inputExtraWrapperTarget.classList.toggle("invisible")
    if (!e.currentTarget.checked) {
      this.inputExtraTarget.value = ""
    }
  }

  selectCategory(e) {
    const selectEl = e.target 
    const url = `/admin/product_sizes?category=${selectEl.value}`
    fetch(url).then(response => response.json())
              .then((data) => {
                this.productSizesCheckboxTargets.forEach((input) => {
                  if (data.includes(Number(input.value))) {
                    input.parentElement.classList.remove('d-none')
                  } else {
                    input.parentElement.classList.add('d-none')
                  }
                })
              })
  }
}
