import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ["input", "form", 'modules', 'check']

  toogleCheck() {
    this.inputTargets.forEach((input) => {
      if (input.checked) {
        input.classList.add('active')
        if (input.value === 'basique') {
          this.modulesTarget.classList.remove('d-none')
        } else {
          this.modulesTarget.classList.add('d-none')
          this.checkTargets.forEach((check) => {
            check.checked = false;
            check.classList.remove('active');
          })
        }
      } else {
        input.classList.remove('active')
        input.removeAttribute('checked')
      }
    })
  }

  submitForm() {

    this.formTarget.submit()
  }

  toggleCheckbox() {
    this.checkTargets.forEach((check) => {
      if (check.checked) {
        check.classList.add('active')
      } else {
        check.classList.remove('active')
      }
    })
  }

}
