const tableRowLink = () => {
    const rowLinkList = document.querySelectorAll("tr[data-link]")
    if (rowLinkList) {
      rowLinkList.forEach((row) => {
        row.addEventListener("click", () => {
          window.location = row.dataset.link
        })
      })
    }
  }
  
export { tableRowLink }
  