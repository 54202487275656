import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ["result"]

  connect() {
  }

  handleSuccess(e) {
    const [data, status, xhr] = e.detail
    this.resultTarget.innerHTML = xhr.responseText
  }
}


