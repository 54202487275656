import { Controller } from "stimulus";
import mapboxgl from "mapbox-gl";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";

export default class extends Controller {
  static values = {
    apiKey: String,
    markers: Array,
    searchControl: Boolean
  }

  connect() {
    mapboxgl.accessToken = this.apiKeyValue
    this.map = new mapboxgl.Map({
      container: this.element,
      style: "mapbox://styles/mapbox/streets-v10"
    })
    this.#addMarkersToMap()
    this.#fitMapToMarkers()

    if (this.searchControlValue) {
      this.#addSearchControl()
    }
  }

  #addMarkersToMap() {
    this.markersValue.forEach((marker) => {
      let customMarker
      if (marker.image_url) {
        // Create a HTML element for your custom marker
        customMarker = document.createElement("div")
        customMarker.className = "marker"
        customMarker.style.backgroundImage = `url('${marker.image_url}')`
        customMarker.style.backgroundSize = "contain"
        customMarker.style.backgroundRepeat = "no-repeat"
        customMarker.style.width = "40px"
        customMarker.style.height = "40px"
        customMarker.dataset.category = marker.category
        customMarker.id = marker.id
      }

      // Pass the element as an argument to the new marker
      const mapboxMarker = new mapboxgl.Marker(customMarker)
                              .setLngLat([marker.lng, marker.lat])
                              .addTo(this.map)
        if (marker.info_window) {
          const popup = new mapboxgl.Popup().setHTML(marker.info_window)
          mapboxMarker.setPopup(popup)
        }
      })
  }

  #fitMapToMarkers() {
    const bounds = new mapboxgl.LngLatBounds()
    this.markersValue.forEach(marker => bounds.extend([ marker.lng, marker.lat ]))
    this.map.fitBounds(bounds, { padding: 70, maxZoom: 15, duration: 0 })
  }

  #addSearchControl() {
    const geocoder = new MapboxGeocoder(
      { accessToken: mapboxgl.accessToken,
        mapboxgl: mapboxgl
      })
    this.map.addControl(geocoder)
  }

  filterMarkers(e) {
    let markers = this.map._markers.filter((marker) => marker._element.dataset.category === e.target.value)
    if (e.target.checked) {
      markers.forEach((m) => m._element.classList.remove('d-none'))
    } else {
      markers.forEach((m) => m._element.classList.add('d-none'))
    }
  }
}
