import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "form", "input", "roomsList", "maxRoomAlert"]

  isMaxRoomCountReached() {
    const params = JSON.parse(this.formTarget.dataset.params)
    return (params.roomsCount + 1 >= params.maxRooms)
  }

  createSuccess(event){
    // const [data, status, xhr] = event.detail
    // this.roomsListTarget.insertAdjacentHTML('beforeend', xhr.response)
    // this.inputTarget.value = ""
    // if (this.isMaxRoomCountReached()) {
    //   this.formTarget.classList.add("d-none")
    //   this.maxRoomAlertTarget.classList.remove("d-none")
    // }
  }

  createError(event) {
    const [data, status, xhr] = event.detail
    this.formTarget.innerHTML = xhr.response
    this.formTarget.querySelector(".invalid-feedback").classList.add("d-block")
  }

}
