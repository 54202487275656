import consumer from "./consumer";

const initOrderCable = () => {
  const ritmobileLayout = document.getElementById("ritmobile-layout")
  const ritmobileOrderCard = document.getElementById("room-cart")
  const adminLayout = document.getElementById("admin-layout")
  const adminOrdersTable = document.getElementById("orders-table")

  const updateOrdersCount = (count) => {
    const orderCount = document.querySelector("nav.navbar #orders-count")
    if (orderCount) {
      orderCount.innerText = count
    }
  }

  const displayAdminAlert = (alert) => {
    if (adminLayout) {
      document.body.insertAdjacentHTML('afterbegin', alert)
    }
  }

  const displayRoomAlert = (roomId, alert) => {
    const currentRoomId = ritmobileLayout?.dataset.roomId
    if (currentRoomId == roomId) {
      document.body.insertAdjacentHTML('afterbegin', alert)
    }
  }

  const addOrderRow = (row) => {
    adminOrdersTable.querySelector("tbody").insertAdjacentHTML('afterbegin', row)
  }

  const addOrderToTable = (data) => {
    if (adminOrdersTable && (adminOrdersTable.dataset.filter === "all" || adminOrdersTable.dataset.filter === data.state)) {
      addOrderRow(data.content)
    }
  }

  const updateOrderStateAdminTable = (data) => {
    const orderRow = document.querySelector(`#order-${data.order_id}`)
    if (orderRow && adminOrdersTable?.dataset.filter === "all") {
      orderRow.querySelector(".order-state").innerHTML = data.content
    } else if (orderRow) {
      orderRow.remove()
    } else if (adminOrdersTable?.dataset.filter === "cancelled") {
      addOrderRow(data.content)
    }
  }

  const updateOrderRoomCart = (data) => {
    const orderRow = ritmobileOrderCard?.querySelector(`#order-${data.order_id}`)
    const orderDetailRow = ritmobileOrderCard?.querySelector(`#orderDetail-${data.order_id}`)
    const ordersWrapper = document.getElementById(`orders-${data.state}`)
    if (orderRow && orderDetailRow) {
      orderRow.remove()
      orderDetailRow.remove()
    }
    if (ordersWrapper) {
      ordersWrapper.insertAdjacentHTML('afterbegin', data.content)
    }
  }

  const updateOrder = (data) => {
    data.from === "admin" ?  updateOrderRoomCart(data) : updateOrderStateAdminTable(data)
  }

  if (ritmobileLayout || adminLayout) {
    const hotelId = ritmobileLayout ? ritmobileLayout.dataset.hotelId  : adminLayout.dataset.hotelId
    const orderChannel = consumer.subscriptions.create( {channel: "OrderChannel", hotel_id: hotelId}, {
      connected() {
        // console.log("Connected")
      },
      received(data) {
        if (data.alert) {
          data.from === "admin" ? displayRoomAlert(data.room_id, data.alert) : displayAdminAlert(data.alert)
        }
        updateOrdersCount(data.orders_count)

        if (data.action === "new_order") {
          addOrderToTable(data)
        } else if (data.action === "cancel_order") {
          updateOrder(data)
        } else if (data.action === "finish_order") {
          updateOrder(data)
        }  else if (data.action === "update_order") {
          updateOrder(data)
        }
      },
      disconnected() {
        // console.log("Disconnected");
      }
    })
  }


}

export { initOrderCable }
