import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ["inputWrapper", "input", "tab"]

  connect() {
    if (this.hasTabTarget) {
      this.tabTarget.classList.add('active')
    }
    if (this.hasInputWrapperTarget) {
      this.inputWrapperTarget.classList.remove('d-none')
    }
    this.inputTargets.forEach(input => this.isEmptyInput(input))
  }

  toggleInput(e) {
    this.tabTargets.forEach((tab) => {
      tab.classList.remove('active')
    })
    e.currentTarget.classList.add('active')
    this.inputWrapperTargets.forEach((input) => {
      input.classList.add('d-none')
    })
    const tabLangData = e.currentTarget.dataset.lang
    const input = this.inputWrapperTargets.find(input => input.dataset.lang === tabLangData)
    input.classList.remove('d-none')
  }

  setEmptyClass(e) {
    this.isEmptyInput(e.currentTarget)
  }
  isEmptyInput(input) {
    const inputLangData = input.parentElement.dataset.lang
    const tab = this.tabTargets.find(tab => tab.dataset.lang === inputLangData)
    if (input.value) {
      tab.classList.remove('is-empty')
    } else {
      tab.classList.add('is-empty')
    }
  }
}


