import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ["img", "photoInput", "deleteInput", "deleteIcon"]

  displayPreview(event){
    if (this.photoInputTarget.files && this.photoInputTarget.files[0]) {

      const reader = new FileReader()
      reader.onload = (event) => {
        this.imgTarget.src = event.currentTarget.result
        this.imgTarget.classList.remove("d-none")
        if (this.hasDeleteIconTarget) {
          this.deleteIconTarget.classList.remove("d-none")
        }
      }
      reader.readAsDataURL(this.photoInputTarget.files[0])
    }
  }

  deleteImg(event){
    event.preventDefault()
    this.imgTarget.src = ""
    this.imgTarget.classList.add("d-none")

    if (this.hasDeleteIconTarget) {
      this.deleteIconTarget.classList.add("d-none")
    }
    if (this.photoInputTarget.files && this.photoInputTarget.files[0]) {
      this.photoInputTarget.value = ""
    } else {
      this.deleteInputTarget.value = true
    }

  }
}


