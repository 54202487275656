import consumer from "./consumer";

const superAdminTicketsContainer = document.getElementById("accordion-tickets-opened")
const hotelTicketContainer = document.getElementById("accordion-admin-tickets")
const hotelId = document.getElementById("admin-layout")?.dataset.hotelId

const insertTicket = (ticket) => {
  superAdminTicketsContainer.insertAdjacentHTML("afterbegin", ticket)
}

const insertAnswer = (form, content) => {
  form.insertAdjacentHTML("beforebegin", content)
}

const updateSuperAdminTickets = (data) => {
  const ticketId = data.ticket_id

  if (data.action == "new_answer"){
    const ticketAnswerForm = document.getElementById(`answer-form-ticket-${ticketId}`)
    insertAnswer(ticketAnswerForm, data.content)
  }
  else {
    insertTicket(data.content)
  }
}

const closeTicket = (id) => {
  const ticketCard = document.getElementById(`ticket-${id}`)
  const badge = ticketCard.querySelector(".card-header span.badge-pill")
  badge.innerText = "Fermé"
  badge.classList.remove("badge-dark")
  badge.classList.add("badge-danger")

}

const displayAnswerToHotel = (data) => {
  const ticketId = data.ticket_id
  const ticketAnswerForm = document.getElementById(`answer-form-ticket-${ticketId}`)
  insertAnswer(ticketAnswerForm, data.content)
}

const subTicketChannel = () => {
  consumer.subscriptions.create({channel: "TicketChannel"}, {
    connected() {
    },
    received(data) {
      if (superAdminTicketsContainer) {
        updateSuperAdminTickets(data)
      } else if (data.hotel_id == hotelId && hotelTicketContainer) {
        if (data.action == "new_answer") {
          displayAnswerToHotel(data)
        } else if (data.action == "close_ticket") {
          const ticketId = data.ticket_id
          closeTicket(ticketId)
        }

      }
    }
  })
}

const initTicketCable = () => {
  subTicketChannel()
}

export { initTicketCable }
