import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ["input"]

  connect() {
  }

  change(event) {
    if (!event.target.checked) { return }
    const inputsToUnckeck = this.inputsToUncheck(event.target)
    this.uncheckInputs(inputsToUnckeck)
  }

  inputsToUncheck(inputChecked) {
    return this.inputTargets.filter(input => input !== inputChecked)
  }

  uncheckInputs(inputs) {
    inputs.forEach(input =>  input.checked = false)
  }
}
