import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["btn", "container", "link"]

  connect() {
  }

  toggleBtn() {
    if (this.containerTarget.scrollTop > 40) {
      this.btnTarget.style.display = "block";
    } else {
      this.btnTarget.style.display = "none";
    }
  }

  scrollToTop() {
    this.containerTarget.scrollTop = 0
  }

  scrollToTarget(e) {
    e.preventDefault()
    const scrollTarget = e.currentTarget.dataset.target
    this.element.querySelector(scrollTarget).scrollIntoView({block: "start", behavior: "smooth"}) 
  }
}
