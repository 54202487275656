import { Controller } from "stimulus"
import $ from 'jquery';

export default class extends Controller {
  connect() {
    $(function () {
      $('[data-toggle="tooltip"]').tooltip({
        trigger : 'hover',
        container: 'body',
        html: true
      })
    })
    $('a').click(function(){
      $('[data-toggle="tooltip"]').tooltip('hide');
    });
  }
}
