import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["btn", "container"]

  connect() {
  }

  minimizeBtn() {
    if (!this.hasBtnTarget) {
      return
    }
    if (this.containerTarget.scrollTop > 10) {
      this.btnTarget.classList.add("minimize");
    } else {
      this.btnTarget.classList.remove("minimize");
    }
  }
}
