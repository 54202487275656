import { Controller } from 'stimulus';
import QrScanner from 'qr-scanner';

export default class extends Controller {
  static targets = ["video"]

  connect() {
    this.qrScanner = new QrScanner(this.videoTarget, result => this.setResult(result), error => {});
    // window.scanner = qrScanner;
    this.qrScanner.start().then(() => {
      this.showScan()
    })

  }
  showScan() {
    this.videoTarget.parentNode.insertBefore(this.qrScanner.$canvas, this.videoTarget);
    this.qrScanner.$canvas.classList.add('reader-preview')
  }

  setResult(result) {
    window.location.href = result
    this.qrScanner.stop();
  }
}
