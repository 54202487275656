import LocalTime from "local-time"
import { localTimeFr, localTimeEs } from "./local_time_translation"
import { getCookie } from "./cookies"

const initLocalTime = () => {
  LocalTime.config.i18n["fr"] = localTimeFr()
  LocalTime.config.i18n["es"] = localTimeEs()
  LocalTime.config.locale = getCookie("_room_in_touch_locale")
  LocalTime.start()
}

export { initLocalTime }
