import { Controller } from 'stimulus';
import $ from 'jquery';
import 'select2';

export default class extends Controller {
  static targets = ["select"]

  connect() {
    $(this.selectTarget).select2({ width: '100%' });
  }
}
