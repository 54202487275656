import NestedForm from "stimulus-rails-nested-form"

export default class extends NestedForm {
  connect() {
  }

  add(e) {
    const el = e.target.closest(".nested-form")
    const template = this.templateTargets.find(template => template.parentElement === el)
    const target = this.targetTargets.find(target => target.parentElement === el)
 
    const model = e.target.dataset.model.toUpperCase()
    const regex = new RegExp(`NEW_${model}_RECORD`, 'g')
    
    const content = template.innerHTML.replace(regex, new Date().getTime().toString())
    target.insertAdjacentHTML('beforebegin', content)
  }

  remove(e) {
    super.remove(e)
  }
}