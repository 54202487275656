import { Controller } from "stimulus"
import consumer from "../channels/consumer"

export default class extends Controller {
  static targets = ["badgeNavbar", "badgeChatroomRow"]

  connect() {
    this.channel = consumer.subscriptions.create(
      { channel: "NotificationNewMessagesChannel" },
      { received: (data) => {
        this.updateNotificationsCountInNavbar()
        this.displayChatroomRow(data)
      }
    })
  }
  
  updateNotificationsCountInNavbar () {
    if (this.hasBadgeNavbarTarget) {
      let NotifCount = Number(this.badgeNavbarTarget.innerText) || 0
      this.badgeNavbarTarget.innerText = NotifCount + 1
    }
  }

  displayChatroomRow (data) {
    const chatroomList = document.getElementById("chatrooms-list")
    if (chatroomList) {
      const chatroomRow = document.getElementById(`chatroom-${data.chatroom_id}`)
      if (chatroomRow) {
        chatroomRow.remove()
      } 
      chatroomList.insertAdjacentHTML("afterbegin", data.chatroom_row)
    }
  }

  disconnect() {
    this.channel.unsubscribe()
  }
}
