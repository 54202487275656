import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["burgerIcon", "menu"]

  toggleMenu() {
    this.burgerIconTarget.classList.toggle("open")
    this.menuTarget.classList.toggle("show")
  }

}
