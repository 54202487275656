const cgvEventBtn = () => {
  const cgvBtn = document.getElementById('cgv');
  const payBtn = document.getElementById('pay');
  if (cgvBtn) {
    cgvBtn.addEventListener('change', (event) => {
      if (event.currentTarget.checked) {
        payBtn.disabled = false;
      } else {
        payBtn.disabled = true;
      }
    });
  }
}

export { cgvEventBtn}
