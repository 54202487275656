import { Controller } from "stimulus"
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder"
import mapboxgl from "mapbox-gl";

export default class extends Controller {
  static values = { 
    apiKey: String,
    map: Boolean,
    hotelCoords: Array
  }

  static targets = ["address"]

  connect() {
    this.#getCoordInputs()
    if (this.mapValue) {
      this.#setMap()
    }
    this.geocoder = new MapboxGeocoder({
      accessToken: this.apiKeyValue,
      types: "country,region,place,postcode,locality,neighborhood,address",
      proximity: 'ip'
    });
    this.geocoder.addTo(this.element)
    this.geocoder.on("result", event => this.#setInputsValuesAndMarker(event))
    this.geocoder.on("clear", () => this.#clearInputValue())
    if (this.addressTarget.value) {
      this.geocoder._inputEl.value = this.addressTarget.value
    } else {
      this.geocoder._geolocateUser()
    }
    // To not focus on and clear geocoder input when keydown 'enter'
    this.geocoder._clearEl.type = "button"
  }

  #setMap () {
    mapboxgl.accessToken = this.apiKeyValue
    const mapContainer = document.createElement("div")
    mapContainer.style.width = '100%'
    mapContainer.style.height = '400px'
    mapContainer.classList.add('my-4')
    this.element.insertAdjacentElement('afterend', mapContainer)
    const content = "<div class='text-muted small mx-n2 bg-light py-2 px-2 rounded'>L'adresse voulue n'est pas présente dans la liste ou l'emplacement n'est pas correct? Cliquez sur la carte pour placer manuellement le marqueur à l'endroit désiré</div>"
    mapContainer.insertAdjacentHTML('beforebegin', content)

    this.map = new mapboxgl.Map({
      container: mapContainer,
      style: "mapbox://styles/mapbox/streets-v10"
    })
    if (this.latInput.value && this.lngInput.value) {
      this.#addMarkerToMap([this.lngInput.value, this.latInput.value])
      this.#fitMapToCoords([this.lngInput.value, this.latInput.value])
    } else if (this.hotelCoordsValue.length === 2) {
      this.#fitMapToCoords(this.hotelCoordsValue)
    }
    this.map.on('click', this.#updateMarkerAndCoords.bind(this))
  }

  #updateMarkerAndCoords(e) {
    const coords = [e.lngLat.lng, e.lngLat.lat]
    this.#addMarkerToMap(coords)
    this.#updateCoordsInputsValues(coords)
  }

  #fitMapToCoords(coords) {
    const bounds = new mapboxgl.LngLatBounds(coords, coords)
    this.map.fitBounds(bounds, { padding: 70, maxZoom: 13, duration: 0 })
  }

  #addMarkerToMap (coords) {
    this.#removeMarker()
    const mapboxMarker = new mapboxgl.Marker()
    .setLngLat(coords)
    .addTo(this.map)
  }

  #removeMarker() {
    this.map._markers.forEach((marker) => marker.remove())
  }

  #getCoordInputs() {
    const parentEl = this.element.parentElement
    this.latInput = parentEl.querySelector('input[id$="latitude"]')
    this.lngInput = parentEl.querySelector('input[id$="longitude"]')
  }

  #setInputsValuesAndMarker(event) {
    this.addressTarget.value = event.result["place_name"]
    let coords = event.result.geometry.coordinates
    this.#updateCoordsInputsValues(coords)
    if (this.mapValue) {
      this.#addMarkerToMap(coords)
      this.#fitMapToCoords(coords)
    }
  }

  #updateCoordsInputsValues(coords) {
    if (this.lngInput && this.latInput) {
      this.lngInput.value = coords[0] 
      this.latInput.value = coords[1]
    }
  }

  #clearInputValue() {
    // console.trace("clear")
    // console.log(e)
    this.addressTarget.value = ""
    if (this.lngInput && this.latInput) {
      this.lngInput.value = ""
      this.latInput.value = ""
    }
  }
}