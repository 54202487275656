import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    "iconMinus",
    "iconPlus",
    "quantity", 
    "checkboxes",
    "alertQuantity"
  ]

  connect() {
    this.setOpacityIcons()
  }

  decrement(e) {
    const icon = e.currentTarget
    const quantity = this.computeQuantity(icon, -1)
    const input = this.getInput(icon)
    if (quantity >= 0 ) {
      quantity === 0 ? this.addIconOpacity(icon) : this.removeIconOpacity(icon)
      this.hideAlertMaxQuantity()
      this.updateQuantityProduct(input, quantity)
    }
  }

  increment(e) {
    const quantity = this.computeQuantity(e.currentTarget, 1)
    const input = this.getInput(e.currentTarget)
    if (this.canAddItem()) {
      this.hideAlertMaxQuantity()
      if (this.sufficientStock(input, quantity)) {
        this.updateQuantityProduct(input, quantity)
      }
    } else {
      this.displayAlertMaxQuantity()
      this.addOpacityAllPlusIcons()
    }
  }

  changeQuantity(e) {
    let quantity = e.currentTarget.value
    const input = e.currentTarget
    const stock = parseInt(input.dataset.stock, 10)
    const quantityAvailable = this.remainingQuantityAvailable(input)
    if ( quantity > stock) {
      quantity = stock
    }
    if (quantityAvailable < quantity) {
      this.displayAlertMaxQuantity()
      quantity = quantityAvailable
    } else {
      this.hideAlertMaxQuantity()
    }
    input.value = quantity
    this.checkInputBoxes(input, quantity)
    this.setOpacityIcons()
  }

  remainingQuantityAvailable(currentInput) {
    const totalQuantity = this.quantityTargets.filter(input => input !== currentInput)
                                              .map(input => Number(input.value))
                                              .reduce((previousValue, currentValue) => previousValue + currentValue, 0)
    return 2 - totalQuantity
  }

  sufficientStock(input, quantityRequested) {
    const stock = parseInt(input.dataset.stock, 10)
    return  quantityRequested <= stock
  }

  updateQuantityProduct (input, quantity) {
    this.updateInputQuantity(input, quantity)
    this.checkInputBoxes(input, quantity)
    this.setOpacityIcons()
  }

  computeQuantity(el, i) {
    // this.alertQuantityTarget.classList.add('d-none')
    const input = this.getInput(el)
    return input.value === '' ? i : (Number(input.value) + i)
  }

  canAddItem() {
    const totalQuantity = this.quantityTargets.map(input => Number(input.value))
                                              .reduce((previousValue, currentValue) => previousValue + currentValue, 0)
    return totalQuantity < 2
  }

  updateInputQuantity(input, quantity) {
    input.value = quantity
  }

  checkInputBoxes(input, quantity) {
    const productSizeId = input.dataset.productSizeId
    const productSizeCheckboxes = this.checkboxesTarget.querySelectorAll(`input[value='${productSizeId}']`)
    for (let i = 0; i < productSizeCheckboxes.length; i++) {
      const input = productSizeCheckboxes[i];
      input.checked = i < quantity
    }
  }

  getInput(el) {
    const wrapper = el.closest(".order-product-form")
    return wrapper.querySelector("input.order-product-form__quantity")
  }

  addOpacityAllMinusIcons() {
    this.iconMinusTargets.forEach(icon => this.addIconOpacity(icon))
  }

  addOpacityAllPlusIcons() {
    this.iconPlusTargets.forEach(icon => this.addIconOpacity(icon))
  }

  removeOpacityAllPlusIcons() {
    this.iconPlusTargets.forEach(icon => this.removeIconOpacity(icon))
  }

  removeIconOpacity(icon) {
    icon.classList.remove('opacity-50')
  }

  addIconOpacity(icon) {
    icon.classList.add('opacity-50')
  }

  setOpacityIcons() {
    this.iconMinusTargets.forEach((icon) => {
      const input = this.getInput(icon);
      (input.value === '' || input.value <= 0) ? this.addIconOpacity(icon) : this.removeIconOpacity(icon)
    })
    this.iconPlusTargets.forEach((icon) => {
      const input = this.getInput(icon);
      const stock = parseInt(input.dataset.stock, 10);
      (!this.canAddItem() || input.value >= stock) ? this.addIconOpacity(icon) : this.removeIconOpacity(icon)
    })
  }

  displayAlertMaxQuantity() {
    this.alertQuantityTarget.classList.remove('d-none')
  }

  hideAlertMaxQuantity() {
    this.alertQuantityTarget.classList.add('d-none')
  }
}
