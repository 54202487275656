import { Controller } from "stimulus"
import consumer from "../channels/consumer"

export default class extends Controller {

  connect() {
    this.channel = consumer.subscriptions.create(
      { channel: "NotificationNewMessagesChannel" },
      { received: data => this.element.classList.add("unread-messages")}
      )
  }
  
  disconnect() {
    this.channel.unsubscribe()
  }
}
