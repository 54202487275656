import { Controller } from "stimulus"

let count

export default class extends Controller {
  static targets = ["form", "input", "attendeesCount"]

  connect() {
    this.count = parseInt(this.inputTarget.value, 10)
  }
  increment() {
    this.count += 1
    this.inputTarget.value = this.count
    this.submit()
  }

  decrement() {
    if (this.count <= 1) return
    this.count -= 1
    this.inputTarget.value = this.count
    this.submit()
  }

  submit(e) {
    Rails.fire(this.element, 'submit')
  }

  updateCount(e) {
    if (typeof(e.target.value) === 'number') {
      this.count = e.target.value
      this.submit()
    }
  }
}
