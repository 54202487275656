import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["form", "results", "icon", "spinner"]
  static values = {
    timeout: Number
  }

  connect () {
    if (!this.hasTimeoutValue) { this.timeoutValue = 500 }
  }
  handleResults(event) {
    const [data, status, xhr] = event.detail
    let html
    if (xhr.status === 200) {
      html = data.querySelector('[data-search-target="results"]').innerHTML
    } else if (xhr.status === 206) {
      html = xhr.responseText
    }
    this.resultsTarget.innerHTML = html
    this.hideSpinner()
  }

  search() {
    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      this.showSpinner()
      Rails.fire(this.formTarget, 'submit')
    }, this.timeoutValue)
  }

  showSpinner() {
    this.iconTarget.classList.add('d-none')
    this.spinnerTarget.classList.remove('d-none')
  }

  hideSpinner() {
    this.spinnerTarget.classList.add('d-none')
    this.iconTarget.classList.remove('d-none')
  }


}
